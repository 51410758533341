import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import FlightSearchForm from "../../components/flight-search-form/FlightSearchForm.component";
import moment from "moment";
import "./Homepage.styles.css";
import { useNavigate, useParams } from "react-router-dom";
import callMob from "../../assets/images/flight/book-tick.png";
import { phoneNum } from "../../utils/globalVars";
import { useState, useEffect } from "react";
import SEO from "../../utils/SEO";


const HomePage = () => {

  const navigate = useNavigate();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleExpClick = (origin, dest) => {
    navigate({
      pathname: "/flights",
      search: `?search_t=${moment().unix()}&tripType=one-way&dep_loc=${origin}&dest_loc=${dest}&dep_dt=${moment().format(
        "YYYY-MM-DD"
      )}&ret_dt=null&fl_cl=ECONOMY&adt=1&chd=0`,
    });
  };

  const [showPopup, setPopup] = useState('active')

  function removePopup() {
    setPopup('')
  }

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  const offersettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: true,
    centerPadding: '100px',
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerPadding: '70px',
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerPadding: '50px',
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: '0px',
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };


  // const [homepageStatus, setCloseHome] = React.useState(false);
  // var [count, setCount] = React.useState(0);
  // const handleToClick = () => {
  //   count = count + 1;
  //   if (count >= 10) {
  //     setCloseHome(true);
  //   document.body.classList.add('overflow');

  //   } else {
  //     setCount(count);
  //     window.location.href = 'tel:' + phoneNum.value;
  //   }
  // }
  // const handleTocall = () => {
  //   window.location.href = 'tel:' + phoneNum.value;
  // }
  return (
    <>
      <SEO
        title="zenstartravels -Ultimate Destination for Cheap Flight Booking"
        description="zenstartravels - Your ultimate destination for hassle-free travel bookings. Find the best deals on flights, and embark on your dream journey today. Visit us"
      />
      {/* <div className={`Modal ${homepageStatus ? "pop-modal-mob" : ""}`} onClick={handleToClick}>
        <a href={`tel:${phoneNum.value}`} className="reservation-popup">
          <div className="reservation-inner">
              <div className="wrapper">
                  <div  className="call-number">
                      <strong><i className="fa fa-phone" aria-hidden="true"></i>{phoneNum.value}</strong>
                      <p>call for reservations</p>
                  </div>
                  <h3>Call for Reservations </h3>
                  <div className="call-btn"><span><i className="fa fa-phone" aria-hidden="true"></i></span>{phoneNum.value}</div>
                  <div className="call-img">
                      <img src={gcallIcon} alt=""/>
                  </div>
              </div>
              <div className="footer">
                  <h3>Call for Reservations</h3>
                  <div className="footer-btn">
                      <span><i className="fa fa-phone" aria-hidden="true"></i></span>
                      <div className="footer-inner">
                          <p>Get Our Best Deals</p>

                          <strong>{phoneNum.value}</strong>
                      </div>
                  </div>
              </div>
          </div>
      </a>
      </div >  */}


      <div className="home-banner">
        <div className="cm-page-center">
          <div className="search-banner home-search-form">
            <h1>Let Zenstartravels bring your dream vacation to new heights.</h1>
            <FlightSearchForm />
          </div>
        </div>
      </div>

      <div className="add-section">
        <div className="cm-page-center">
          <div className="cm-flex cm-flex-wrap">
            <div className="cm-wd-50">
              <div className="column promo-col cm-flex cm-flex-wrap">
                <div className="cm-wd-20">
                  <span className="offer-tag">Offer</span>
                  <span className="curr__Icon">$</span>
                </div>
                <div className="cm-wd-80">
                  <h5>Sign Up and Save Up to <span>$15 off◊</span></h5>
                  <p>our fees on selected regions with a promo code!</p>
                  <div className="promo-code-form">
                    <form action="">
                      <input type="text" placeholder="Enter email address" />
                      <input type="submit" value="Get Promo Code!" />
                    </form>
                  </div>
                  <p><small>Expires May 31, 2023. Restrictions apply</small></p>
                </div>
              </div>
            </div>
            <div className="cm-wd-50">
              <div className="column cm-flex cm-flex-wrap">
                <div className="cm-wd-20">
                  <span className="offer-tag">Call Now</span>
                  <span className="curr__Icon"><i className="fa-solid fa-phone"></i></span>
                  {/* <div className="book-mob">
                      <img src={callMob} alt="" />
                    </div> */}
                </div>
                <div className="just-booking cm-wd-80">
                  <h5>Why to book with call center</h5>
                  <ul>
                    <li>Expert guidance by our Travel experts</li>
                    <li>Immediate booking confirmation</li>
                    <li>24 hour cancellation</li>
                    <li>Plans Flexible payment</li>
                  </ul>
                  <div className="booking-call">
                    <a href={`tel:${phoneNum.value}`} className="cm-btn">Call Now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="service_section">
        <h2>Here's why travelers choose zenstartravels.com</h2>
        <div className="cm-page-center cm-flex cm-flex-wrap">
          <div className="cm-wd-25 single-travel">
            <div className="media">
              <div className="media-left media-middle travel-number">
                <span>01.</span>
              </div>
              <div className="media-body travel-content">
                <h5>Get Great Deals!</h5>
                <p>Choose from 500+ airlines for low airfares!</p>
              </div>
            </div>
          </div>
          <div className="cm-wd-25 single-travel">
            <div className="media">
              <div className="media-left media-middle travel-number">
                <span>02.</span>
              </div>
              <div className="media-body travel-content">
                <h5>Price Match Promise</h5>
                <p>Find low prices to destinations worldwide.</p>
              </div>
            </div>
          </div>
          <div className="cm-wd-25 single-travel">
            <div className="media">
              <div className="media-left media-middle travel-number">
                <span>03.</span>
              </div>
              <div className="media-body travel-content">
                <h5>Easy Cancellations</h5>
                <p>Convenient self-service options available online.</p>
              </div>
            </div>
          </div>
          <div className="cm-wd-25 single-travel">
            <div className="media">
              <div className="media-left media-middle travel-number">
                <span>04.</span>
              </div>
              <div className="media-body travel-content">
                <h5>Expert Guidance</h5>
                <p>Get personalized assistance from our travel experts.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="offer-section">
        <div className=" cm-page-center">
          <div className="offer-title">
            <h2>Our Most Popular Packges</h2>
          </div>
          <div className="offer-slider-row cm-flex cm-flex-wrap">
            <div className="column cm-wd-25" onClick={(e) => handleExpClick("JFK", "LHR")}>
              <div className="col-inner">
                <a href="#" className="image-box">
                  <img src="./images/traditional-gondola-near-world-famous-canal-grande-rialto-bridge.png" alt="" />
                </a>
                <div className="offter-text">
                  <div className="title">San Francisco</div>
                  <div className="cm-flex-type-1">
                    <p><span>JFK <i className="fa-solid fa-arrow-right-arrow-left"></i> LHR Starting from <strong>$155</strong></span></p>
                    {/* <p><strong>$155</strong></p> */}
                  </div>
                  <div className="booking-call">
                    <a href="#" className="cm-btn">Book Now</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="column cm-wd-25" onClick={(e) => handleExpClick("MAD", "LHR")}>
              <div className="col-inner">
                <a href="#" className="image-box">
                  <img src="./images/colosseum-rome-morning-sun-italy.png" alt="" />
                </a>
                <div className="offter-text">
                  <div className="title">Richmond</div>
                  <div className="cm-flex-type-1">
                    <p><span>MAD <i className="fa-solid fa-arrow-right-arrow-left"></i> LHR Starting from <strong>$100</strong></span></p>
                    {/* <p><strong>$100</strong></p> */}
                  </div>
                  <div className="booking-call">
                    <a href="#" className="cm-btn">Book Now</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="column cm-wd-25" onClick={(e) => handleExpClick("ATL", "JFK")}>
              <div className="col-inner">
                <a href="#" className="image-box">
                  <img src="./images/view-great-sphinx.png" alt="" />
                </a>
                <div className="offter-text">
                  <div className="title">New York</div>
                  <div className="cm-flex-type-1">
                    <p><span>ATL <i className="fa-solid fa-arrow-right-arrow-left"></i> JFK Starting from <strong>$120</strong></span></p>
                    {/* <p><strong>$120</strong></p> */}
                  </div>
                  <div className="booking-call">
                    <a href="#" className="cm-btn">Book Now</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="column cm-wd-25" onClick={(e) => handleExpClick("SYD", "LHR")}>
              <div className="col-inner">
                <a href="#" className="image-box">
                  <img src="./images/downtown-chicago-skyline-sunset-illinois-usa.png" alt="" />
                </a>
                <div className="offter-text">
                  <div className="title">Faraway Baston</div>
                  <div className="cm-flex-type-1">
                    <p><span>SYD <i className="fa-solid fa-arrow-right-arrow-left"></i> LHR Starting from <strong>$147</strong></span></p>
                    {/* <p><strong>$147</strong></p> */}
                  </div>
                  <div className="booking-call">
                    <a href="#" className="cm-btn">Book Now</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="column cm-wd-25" onClick={(e) => handleExpClick("BER", "LHR")}>
              <div className="col-inner">
                <a href="#" className="image-box">
                  <img src="./images/paris-eiffel-tower-skyline-aerial-france.png" alt="" />
                </a>
                <div className="offter-text">
                  <div className="title">Colorful Paris</div>
                  <div className="cm-flex-type-1">
                    <p><span>BER <i className="fa-solid fa-arrow-right-arrow-left"></i> LHR Starting from <strong>$135</strong></span></p>
                    {/* <p><strong>$135</strong></p> */}
                  </div>
                  <div className="booking-call">
                    <a href="#" className="cm-btn">Book Now</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="column cm-wd-25" onClick={(e) => handleExpClick("TYO", "BOS")}>
              <div className="col-inner">
                <a href="#" className="image-box">
                  <img src="./images/tokyo.jpg" alt="" />
                </a>
                <div className="offter-text">
                  <div className="title">United Kingdom</div>
                  <div className="cm-flex-type-1">
                    <p><span>TYO <i className="fa-solid fa-arrow-right-arrow-left"></i> BOS Starting from <strong>$189 </strong></span></p>
                    {/* <p><strong>$189</strong></p> */}
                  </div>
                  <div className="booking-call">
                    <a href="#" className="cm-btn">Book Now</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="column cm-wd-25" onClick={(e) => handleExpClick("LHR", "JFK")}>
              <div className="col-inner">
                <a href="#" className="image-box">
                  <img src="./images/big-ben-clock-tower-london-england.png" alt="" />
                </a>
                <div className="offter-text">
                  <div className="title">London</div>
                  <div className="cm-flex-type-1">
                    <p><span>LHR <i className="fa-solid fa-arrow-right-arrow-left"></i> JFK Starting from <strong>$135</strong></span></p>
                    {/* <p><strong>$135</strong></p> */}
                  </div>
                  <div className="booking-call">
                    <a href="#" className="cm-btn">Book Now</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="column cm-wd-25" onClick={(e) => handleExpClick("AMS", "LOS")}>
              <div className="col-inner">
                <a href="#" className="image-box">
                  <img src="./images/amsterdam.jpg" alt="" />
                </a>
                <div className="offter-text">
                  <div className="title">Chicago</div>
                  <div className="cm-flex-type-1">
                    <p><span>AMS <i className="fa-solid fa-arrow-right-arrow-left"></i> LOS Starting from <strong>$110</strong></span></p>
                    {/* <p><strong>$110</strong></p> */}
                  </div>
                  <div className="booking-call">
                    <a href="#" className="cm-btn">Book Now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="cm-section review-section">
        <div className="cm-page-center">
          <h2 className="cm-txt-center">What Travellers Say About Us</h2>

          <Slider {...settings} className="cm-home-guide-slider">
            <div className="cm-home-guide-slide">
              <p>Flight Trip Charge created an incredible tour for my family and relatives employing pocket-friendly last-minute travel flights.</p>
              <div className="cm-txt-center ratings">
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <p>Jonathan Smith</p>
              </div>
            </div>
            <div className="cm-home-guide-slide">
              <p>Wonderful time with you all! The service was really helpful in aiding me with all aspects of my flight change procedures.</p>
              <div className="cm-txt-center ratings">
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <p>Daniel Baci</p>
              </div>
            </div>
            <div className="cm-home-guide-slide">
              <p>Wonderful time with you all! The service was really helpful in aiding me with all aspects of my flight change procedures.</p>
              <div className="cm-txt-center ratings">
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <p>Daniel Baci</p>
              </div>
            </div>
            <div className="cm-home-guide-slide">
              <p>Wonderful time with you all! The service was really helpful in aiding me with all aspects of my flight change procedures.</p>
              <div className="cm-txt-center ratings">
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <p>Daniel Baci</p>
              </div>
            </div>
            <div className="cm-home-guide-slide">
              <p>Wonderful time with you all! The service was really helpful in aiding me with all aspects of my flight change procedures.</p>
              <div className="cm-txt-center ratings">
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <p>Daniel Baci</p>
              </div>
            </div>
          </Slider>
        </div>
      </div>

    </>

  );
};

export default HomePage;
